import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { TokenService } from '../services/token.service';

export const tokenInterceptor: HttpInterceptorFn = (req, next) => {
  const tokenService = inject(TokenService);
  const at = tokenService.accessToken;

  const newReq = req.clone({
    setHeaders: { Authorization: `Bearer ${at}` },
  });
  return next(newReq);
};
